import { createContext } from "react";
import { IUseJogo } from "../Pages/Jogo/Hook/useJogo";
import { ICtxAppParams } from "./ctxAppParams";
import { IUseStateStorage } from "../Components/useStateStorage";

export interface ICtxApostaCompra {
    Jogo: IUseJogo;
    formatter: any;
    AppParams: ICtxAppParams;

    exibeCarrinho: boolean | undefined;
    setExibeCarrinho: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    exibeRepete: boolean;
    setExibeRepete: React.Dispatch<React.SetStateAction<boolean>>;
    
    Carrinho : IUseStateStorage<Array<number[]> | undefined>;
}

export const CtxApostaCompra = createContext<ICtxApostaCompra>({
    
} as ICtxApostaCompra);