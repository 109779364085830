import { Button, Card } from "react-bootstrap"
import { IAposta } from "../../Interfaces/Aposta";
import { IUseColorScheme } from "../aposta/colorScheme";
import Dezena from "../aposta/dezena";
import { FaTrashAlt } from "react-icons/fa";
import { useContext, useState } from "react";
import { CtxPremioAposta } from "../../Contexts/ctxPremioAposta";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Colors: IUseColorScheme;
    MaxDigits: number;
    Aposta: IAposta;
    Detalhado?: boolean; // Mostrará detalhes
    Gerencial?: boolean; // Mostrará detalhes sensiveis caso o detalhado esteja ativo
}

const CartaoPuleAposta = ({Aposta, Colors, MaxDigits, Detalhado=false, Gerencial=false, ...rest}: Props) => {
    const PremioAposta = useContext(CtxPremioAposta);
    const [deletado, setDeletado] = useState<boolean>(false);
    return (
            <Card className="mt-3" bg={'light'} border={deletado ? 'danger' : 'light'}>
                <Card.Header className="d-flex w-100 justify-content-between">
                    <div>
                        {Detalhado 
                            ? `${Aposta.NOME}`
                            : `Aposta #${Aposta.CD_JOGO_PULE_APOSTA}`
                        }
                    </div>
                    <div>
                        {Detalhado && Gerencial && `Aposta #${Aposta.CD_JOGO_PULE_APOSTA}`}
                    </div>
                </Card.Header>
                <Card.Body className="d-flex justify-content-center flex-wrap">
                    {
                        Aposta.LANCAMENTO && Aposta.LANCAMENTO.map(lancamento => {
                            return <Dezena
                                key={lancamento.NUMERO}
                                Number={lancamento.NUMERO.toString().padStart(MaxDigits, '0')} 
                                Color={lancamento.ACERTO ? Colors.Primary : Colors.Gray}
                                Scale={2}
                            />
                        })
                    }
                </Card.Body>
                {
                    Detalhado && Gerencial &&
                    <Card.Footer className="d-flex w-100 justify-content-between">
                        <div>{`Pule #${Aposta.CD_JOGO_PULE}`}</div>
                        <Button 
                            size="sm" 
                            variant="danger"
                            disabled={deletado}
                            onClick={() => {
                                if (window.confirm(`Tem certeza que deseja remover a premiação da aposta #${Aposta.CD_JOGO_PULE_APOSTA} no nome de ${Aposta.NOME}?`)) {
                                    PremioAposta.ControllerPremio?.Deletar.mutateAsync({CD_JOGO_COMISSAO: PremioAposta.Comissao?.CD_JOGO_COMISSAO, CD_JOGO_PULE_APOSTA: Aposta.CD_JOGO_PULE_APOSTA}, {
                                        onSuccess: () => {
                                            setDeletado(true)
                                        }
                                    })
                                }
                            }}
                        >
                            {
                                deletado
                                ? 'Deletado'
                                : <><FaTrashAlt /> Remover</>
                            }
                        </Button>
                    </Card.Footer>
                }
            </Card>
    )
}

export default CartaoPuleAposta;