import { UseMutationResult, UseQueryResult, useMutation, useQuery } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import useAutenticacao from "../Components/useAuth";
import { IPessoa, IPessoaAcesso } from "../Interfaces/Pessoa";
import { useContext } from "react";
import { CtxAppParams } from "../Contexts/ctxAppParams";

const usePessoaController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = true) : IUsePessoaController => {
    const AppParams = useContext(CtxAppParams);
    const {setToken, getToken} = useAutenticacao();
    const API = useAPI();

    // const primaryKey = 'CELULAR';
    const endpoint = 'pessoa';
    
    const Acessos = useQuery<IPessoaAcesso[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}Acesso/listar`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !!AppParams.Token,
            refetchInterval: 1800000,
            refetchIntervalInBackground: false,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const Login = useMutation(
        (data : DeepPartial<IPessoa>) => 
            API.post(`${endpoint}/login`, data)
        , {
            onSuccess: (ret: IPessoa) => {
                setToken(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IPessoa>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: IPessoa) => {
                if (!getToken()) {
                    setToken(ret);
                }
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Buscar = useMutation(
        (data : DeepPartial<IPessoa>) => 
            API.get(`${endpoint}/buscar/${data['CELULAR']}`, "")
        , {
            onSuccess: (ret: IPessoa) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const BuscarNome = useMutation(
        (data : DeepPartial<IPessoa>) => 
            API.get(`${endpoint}/buscarNome/${data['NOME']}`, "")
        , {
            onSuccess: (ret: IPessoa[]) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const VerificarCadastro = useMutation(
        (data : DeepPartial<IPessoa>) => 
            API.get(`${endpoint}/verificarCadastro/${data['CELULAR']}`, "")
        , {
            onSuccess: (ret: any) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Login       : Login,
        Cadastrar   : Cadastrar,
        Acessos     : Acessos,
        Buscar      : Buscar,
        BuscarNome  : BuscarNome,
        VerificarCadastro: VerificarCadastro
    }
}

export interface IUsePessoaController {
    Login               : UseMutationResult<IPessoa, any, DeepPartial<IPessoa>, unknown>;
    Cadastrar           : UseMutationResult<IPessoa, any, DeepPartial<IPessoa>, unknown>;
    Acessos             : UseQueryResult<IPessoaAcesso[], unknown>; 
    Buscar              : UseMutationResult<IPessoa, any, DeepPartial<IPessoa>, unknown>;
    BuscarNome          : UseMutationResult<IPessoa[], any, DeepPartial<IPessoa>, unknown>;
    VerificarCadastro   : UseMutationResult<any, any, DeepPartial<IPessoa>, unknown>;
}

export default usePessoaController;