import { Button, Card } from "react-bootstrap";
import useColorScheme from "./colorScheme";
import Dezena from "./dezena";
import { FaTrashAlt } from "react-icons/fa";
import { v4 } from "uuid";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Carrinho?: number[][] | null;
    setCarrinho: React.Dispatch<React.SetStateAction<Array<number[]> | undefined>>;
    VlrMax: number;
}

const CartaoAposta = ({VlrMax, Carrinho, setCarrinho, ...rest}: Props) => {
    const Colors = useColorScheme();

    const maxDigits = VlrMax.toString().length;

    return (
        <div className="d-flex flex-wrap justify-content-center" {...rest}>
            {
                Carrinho && Carrinho.length > 0 ?
                    Carrinho.map((aposta, index) => {
                        return (
                            <Card className="my-1" bg="light" border="light" key={v4.toString()}>
                                <Card.Header>
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <div>{`Cartão #${(Carrinho.length+1) - (index+1)}`}</div>
                                        <div><Button 
                                            size="sm" 
                                            variant="danger" 
                                            onClick={() => 
                                                setCarrinho(prev => prev!.filter((_, i) => i !== index))
                                            }
                                        >
                                            Remover <FaTrashAlt />
                                        </Button></div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="w-100 d-flex flex-wrap justify-content-center">
                                    {
                                        aposta.map(numero => {
                                            return (
                                                <Dezena
                                                    key={numero}
                                                    Color={Colors.Primary}
                                                    Number={String(numero).padStart(maxDigits, '0')}
                                                    Scale={2.5}
                                                />
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        )                    
                    })
                :
                    rest.children
            }
        </div>
    )
}

export default CartaoAposta;