import { useContext, useMemo } from "react";
import { IPule } from "../../../../Interfaces/Pule";
import CartaoPule from "../../../../Components/pule/cartaoPule";
import useColorScheme from "../../../../Components/aposta/colorScheme";
import { IJogo } from "../../../../Interfaces/Jogo";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";

interface Props {
    selecaoPule: IPule[];
}

const E3Pule = ({selecaoPule} : Props) => {
    const { Jogo, formatter, setExibeRepete, Carrinho } = useContext(CtxApostaCompra);
    const Colors = useColorScheme();

    const objPules = useMemo(() => {
        return selecaoPule.filter(pule => pule.CD_TIPO_JOGO === Jogo.TipoJogo.CD_TIPO_JOGO)   
    }, [selecaoPule, Jogo.TipoJogo.CD_TIPO_JOGO]);

    const pules = useMemo(() => {
        return (
            objPules && objPules.length > 0 && objPules.map(pule => {
                if (pule.CD_TIPO_JOGO === Jogo.TipoJogo.CD_TIPO_JOGO) {
                    return <CartaoPule
                        Pule={pule}
                        Jogo={pule.JOGO ?? {VLR_APOSTA: 15} as IJogo}
                        MaxDigits={Jogo.TipoJogo.VLR_MAX.toString().length}
                        formatter={formatter}
                        Colors={Colors}
                        ModoExibicao="RepeteAposta"
                        HandleRepeteAposta={(e) => {
                            Carrinho.Write(e);
                            setExibeRepete(false);
                        }}
                    />
                } else {
                    return <></>
                }
            })            
        )
    }, [objPules, Colors, Jogo.TipoJogo.CD_TIPO_JOGO, Jogo.TipoJogo.VLR_MAX, formatter, Carrinho]); //eslint-disable-line

    return (
        <>
            {
                objPules && objPules.length > 0
                    ? pules
                    : <div>Não foi encontrado nenhuma pule para o apostador pesquisado no tipo de jogo atual.</div>
            }
        </>
    )
}

export default E3Pule;