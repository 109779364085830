import { useContext, useMemo, useState } from "react";
import ModalConfirmacao from "../../../../Components/ModalConfirmacao";
import { IPessoa } from "../../../../Interfaces/Pessoa";
import usePessoaController from "../../../../Controllers/Pessoa";
import { IGenericInformation } from "../../../../Interfaces";
import StepsIndicator from "../../../../Components/formularios/StepsIndicator";
import E1Pesquisa from "./E1Pesquisa";
import E2ListaApostador from "./E2ListaApostador";
import { IPule } from "../../../../Interfaces/Pule";
import E3Pule from "./E3Pule";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";

const JogoApostaRepete = () => {
    const { Jogo, exibeRepete, setExibeRepete, formatter } = useContext(CtxApostaCompra);
    const { Buscar, VerificarCadastro, BuscarNome } = usePessoaController('Pessoa', true, false);
    
    const vinculaApostadorDefault = [
        {
            CHAVE: 'PULE',
            VALOR: ''
        },
        {
            CHAVE: 'CELULAR',
            VALOR: ''
        },
        {
            CHAVE: 'NOME',
            VALOR: ''
        }
    ];
    
    const [estagio, setEstagio] = useState<'1' | '2' | '3'>('1');
    const [apostador, setApostador] = useState<IPessoa>({} as IPessoa); //eslint-disable-line
    const [selecaoApostador, setSelecaoApostador] = useState<IPessoa[]>([]);
    const [selecaoPule, setSelecaoPule] = useState<IPule[]>([]);
    const [vinculaApostador, setVinculaApostador] = useState<IGenericInformation[]>(vinculaApostadorDefault);

    const conteudo = useMemo(() => {
        switch (estagio) {
            case "2"    : return <E2ListaApostador setEstagio={setEstagio} listaApostador={selecaoApostador} setApostador={setApostador} formatter={formatter} setSelecaoPule={setSelecaoPule} Jogo={Jogo} />  // Listar nomes de usuarios pesquisados
            case "3"    : return <E3Pule selecaoPule={selecaoPule} />            // Listar Pules do mesmo tipo de jogo
            default     : return <E1Pesquisa vinculaApostador={vinculaApostador} setVinculaApostador={setVinculaApostador} formatter={formatter} />        // Pesquisar por pule, nome ou celular
        }
    }, [estagio, vinculaApostador, selecaoApostador, selecaoPule, formatter]); //eslint-disable-line

    return (
        <ModalConfirmacao
            titulo={'Repetir aposta'}
            show={exibeRepete} 
            confirmText={['1', '2'].includes(estagio) ? 'Continuar' : undefined}
            cancelText={['2', '3'].includes(estagio) ? 'Voltar' : undefined}
            disabledConfirm={
                ['2', '3'].includes(estagio)
            }            
            onHide={() => {
                if (['2', '3'].includes(estagio)) {
                    setEstagio('1');
                } else {
                    setExibeRepete(false); 
                    setEstagio('1');
                }                
            }}
            onSubmitHandler={() => {
                if (estagio === '1') { // Pesquisar por pule, nome ou celular
                    const tipoBusca = vinculaApostador.find(x => String(x.VALOR ?? "") !== '')?.CHAVE;
                    const valorBusca = tipoBusca === 'CELULAR' 
                        ? formatter.getPhone(vinculaApostador.find(x => x.CHAVE === tipoBusca)?.VALOR ?? "") 
                        : (vinculaApostador.find(x => x.CHAVE === tipoBusca)?.VALOR ?? "");

                    if (tipoBusca === 'PULE') {
                        Jogo.Aposta.Controller.Buscar.mutateAsync({CD_JOGO_PULE: Number(valorBusca)}, {
                            onSuccess: (ret: IPule) => {
                                setSelecaoPule([ret]);
                                setEstagio('3');
                            }
                        })
                    } else if (tipoBusca === 'CELULAR') {
                        if (valorBusca?.length === 11) {
                            VerificarCadastro.mutateAsync({CELULAR: valorBusca}, {
                                onSuccess: (ret: any) => {
                                    if (ret["EXISTE"]) {
                                        Buscar.mutateAsync({CELULAR: valorBusca}, {
                                            onSuccess: (ret: IPessoa) => {
                                                setApostador(ret);
                                                Jogo.Aposta.Controller.BuscarPorUsuario.mutateAsync({CD_APOSTADOR: ret.CD_PESSOA}, {
                                                    onSuccess: (apostas) => {
                                                        setSelecaoPule(apostas);
                                                        setEstagio('3');
                                                    }
                                                });                                                
                                            }
                                        })
                                    } else {
                                        alert('Nenhum apostador encontrado para o celular informado');
                                    }
                                }
                            })                            
                        } else {
                            alert('Celular informado inválido.');
                        }
                    } else if (tipoBusca === 'NOME') {
                        if (valorBusca?.length >= 3) {
                            BuscarNome.mutateAsync({NOME: valorBusca}, {
                                onSuccess: (ret: IPessoa[]) => {
                                    if (ret.length > 0) {
                                        setSelecaoApostador(ret);
                                        setEstagio('2');
                                    } else {
                                        alert('Nenhum apostador encontrado para o nome informado');
                                    }                                    
                                }
                            })
                        } else {
                            alert('Digite um nome válido');
                        }
                    } else {
                        alert('Insira a pule, celular ou nome do apostador para pesquisar a aposta a ser repetida.');
                    }
                } else {
                    alert('Erro na etapa de repetição de aposta, favor encaminhar aos responsáveis técnicos.');
                }
            }}
        >
            <div>
                <StepsIndicator
                    className="mb-3 d-flex justify-content-center"
                    currentStep={
                        (() => {
                            switch (estagio) {
                                case "1": return 1;
                                case "2": return 2;
                                case "3": return 3;                                
                                default: return 1;
                            }
                        })()
                    }
                    totalSteps={3}
                />
                {conteudo}
            </div>
        </ModalConfirmacao>
    )
}

export default JogoApostaRepete;