import { useContext, useLayoutEffect, useMemo } from "react";
import { EMode } from "../../../../Interfaces";
import usePagina from "../../../../Components/usePagina";
import { IUseJogoGrid } from "./grid";
import { IUseJogoDatasource } from "./datasource";
import useJogoGrid from "./grid";
import useJogoController, { IUseJogoController } from "../../../../Controllers/Jogo";
import useJogoDatasource from "./datasource";
import useTipoJogoController from "../../../../Controllers/TipoJogo";
import { UseQueryResult } from "react-query";
import { ITipoJogo } from "../../../../Interfaces/TipoJogo";
import { CtxAppParams } from "../../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../../Constants/permissao";
import { EStatus, IJogo } from "../../../../Interfaces/Jogo";
import useAposta, { IUseAposta } from "../../../Aposta/Hook/useAposta";
import useConcurso, { IUseConcurso } from "../../../Concurso/Hook/useConcurso";

const useJogo = (cdJogo?: string) : IUseJogo => {
    const Codigo = cdJogo;
    const {NavegaPagina} = usePagina();
    const AppParams = useContext(CtxAppParams);
    const AlteraJogo = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.ALTERA_JOGO));
    const Controller = useJogoController({
        updateQueryKey: 'Jogo',
        enableQuery: AlteraJogo,
        enableJogaveis: !AlteraJogo,
    });
    const Datasource = useJogoDatasource({Controller: Controller, AlteraJogo: AlteraJogo});
    const { Listar: ListaTipoJogo } = useTipoJogoController({updateQueryKey: 'TipoJogo', enableQuery: true});
    const Grid = useJogoGrid({Controller: Controller});
    const Validacao = useValidacao(Datasource);
    const Aposta = useAposta(Codigo);
    const Concurso = useConcurso({CodigoJogo: Codigo ?? '0'});

    useLayoutEffect(() => {
        if (Codigo && Number(Codigo) > 0) {
            Controller.Buscar.mutate({CD_JOGO: Number(Codigo)});
        } else {
            Controller.Buscar.reset();
        }
    }, [Codigo]); //eslint-disable-line

    const Modo : EMode = useMemo(() => {
        switch (Codigo) {
            case '0':
                return EMode.Browse;
            case '-1':
                return EMode.Include;
            default:
                return EMode.Edit;
        }
    }, [Codigo]);

    const Navegar = (CodigoJogo: string) => {
        if (CodigoJogo === '0') {
            NavegaPagina('Jogo', [], []);
        } else {
            AlteraJogo
                ? NavegaPagina('JogoCadastro', ['cdJogo'], [CodigoJogo])
                : Datasource.Item.STATUS === EStatus.Aberto 
                    ? NavegaPagina('JogoApostaCadastro', ['cdJogo'], [CodigoJogo])
                    : NavegaPagina('JogoResultado', ['cdJogo'], [CodigoJogo])   
        }
    }

    const TipoJogo = useMemo<ITipoJogo>(() => {
        if (ListaTipoJogo.isFetched && ListaTipoJogo.isSuccess && ListaTipoJogo.data) {
            return ListaTipoJogo.data.find(x => x.CD_TIPO_JOGO === Datasource.Item.CD_TIPO_JOGO) as ITipoJogo
        } else {
            return {VLR_MIN: 0, VLR_MAX: 0, QTD_MIN: 0, QTD_MAX: 0} as ITipoJogo
        }
    }, [ListaTipoJogo, Datasource.Item]);

    return {
        Datasource      : Datasource,
        Grid            : Grid,
        Modo            : Modo,
        Navegar         : Navegar,
        Controller      : Controller,
        NavegaPagina    : NavegaPagina,
        Codigo          : Codigo,
        ListaTipoJogo   : ListaTipoJogo,
        TipoJogo        : TipoJogo,
        AlteraJogo      : AlteraJogo,
        Validacao       : Validacao,
        Aposta          : Aposta,
        Concurso        : Concurso
    }
};

export interface IUseJogo {
    Datasource      : IUseJogoDatasource;
    Grid            : IUseJogoGrid;
    Modo            : EMode;
    Navegar         : (CodigoControle: string) => void;
    Controller      : IUseJogoController;
    NavegaPagina    : (Alias: string, paramNames: string[], paramValues: (string | number)[]) => void;
    Codigo          : string | undefined;
    ListaTipoJogo   : UseQueryResult<ITipoJogo[], unknown>;
    TipoJogo        : ITipoJogo;
    AlteraJogo      : boolean;
    Validacao       : IUseValidacao;
    Aposta          : IUseAposta;
    Concurso        : IUseConcurso;
}

const useValidacao = (Datasource: IUseJogoDatasource) : IUseValidacao => {
    const Status = (StatusNovo: EStatus) : boolean => {
        if (Datasource.Item.STATUS === EStatus.Rascunho) {
            // De: Rascunho somente pode alterar para (Aberto, Cancelado)
            switch (StatusNovo) {
                case EStatus.Rascunho:
                    return true
                case EStatus.Aberto:
                    return true
                case EStatus.Cancelado:
                    return true
                default:
                    return false;
            }
        } else if (Datasource.Item.STATUS === EStatus.Aberto) {
            // De: Aberto somente pode alterar para (Cancelado, Andamento)
            switch (StatusNovo) {
                case EStatus.Aberto:
                    return true
                case EStatus.Andamento:
                    return true
                case EStatus.Cancelado:
                    return true
                default:
                    return false;
            }
        } else if (Datasource.Item.STATUS === EStatus.Andamento) {
            // De: Andamento somente pode alterar para (Cancelado, Finalizado)
            switch (StatusNovo) {
                case EStatus.Andamento:
                    return true
                case EStatus.Finalizado:
                    return true
                case EStatus.Cancelado:
                    return true
                default:
                    return false;
            }
        } else {
            return false;
        }
    };

    const Comissao = (values: IJogo) : boolean => {
        if (values.STATUS === EStatus.Aberto || values.STATUS === EStatus.Andamento) {
            const totalComissao = values.COMISSAO 
                ? values.COMISSAO.map(comissao => comissao.PCE_PERCENTUAL).reduce((acc, vlr) => acc + vlr, 0) 
                : 0;
                
            if (totalComissao !== 100) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return {
        Status: Status,
        Comissao: Comissao
    }
}

interface IUseValidacao {
    Status: (StatusNovo: EStatus) => boolean;
    Comissao: (values: IJogo) => boolean;
}

export default useJogo;