import { useMemo } from "react";
import Tabela from "../../../../Components/tabela";
import { IPessoa } from "../../../../Interfaces/Pessoa";
import { IPule } from "../../../../Interfaces/Pule";
import { IUseJogo } from "../../Hook/useJogo";

interface Props {
    listaApostador  : IPessoa[];
    setEstagio      : React.Dispatch<React.SetStateAction<"1" | "2" | "3">>;
    setApostador    : React.Dispatch<React.SetStateAction<IPessoa>>;
    formatter       : any;
    setSelecaoPule  : React.Dispatch<React.SetStateAction<IPule[]>>;
    Jogo            : IUseJogo;
}

const E2ListaApostador = ({listaApostador, setEstagio, setApostador, formatter, setSelecaoPule, Jogo} : Props) => {
    const dados = useMemo(() => listaApostador, [listaApostador]);
    const colunas = useMemo(() => [
        {
            Header: 'Nome',
            id: 'nome',
            accessor: 'NOME'
        },
        {
            Header: 'Celular',
            id: "celular",
            Cell: (row: any) : any => formatter.setMaskPhone(row.row.original['CELULAR'])
        }        
    ], [formatter]);    

    return (
        <div>
            <Tabela 
                dados={dados}
                colunas={colunas} 
                handleClick={e => {
                    setApostador(e);
                    Jogo.Aposta.Controller.BuscarPorUsuario.mutateAsync({CD_APOSTADOR: e.CD_PESSOA}, {
                        onSuccess: (apostas) => {
                            setSelecaoPule(apostas);
                            setEstagio('3');
                        }
                    });     
                }}
            />
        </div>
    )
}

export default E2ListaApostador;