import { useOutletContext } from "react-router-dom"
import { IUseConcurso } from "./Hook/useConcurso";
import { Button, Card, Container, Nav, Tab } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { TiPlus } from "react-icons/ti";
import { EConcursoStatus, IConcurso } from "../../Interfaces/Concurso";
import { useState } from "react";
import TabelaMais from "../../Components/tabela/tabelaMais";

const ConcursoLista = () => {
    const Concurso = useOutletContext<IUseConcurso>();
    const [aba, setAba] = useState<'Rascunho' | 'Finalizado'>('Rascunho');

    return (
        <Container className="verticalCenter">
            <Tab.Container
                activeKey={aba}
                onSelect={(eventKey) => setAba(eventKey as any)} 
            >
                <Card className="card mt-3 mb-3">
                    <Card.Header>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Rascunho">Rascunho</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Finalizado">Finalizados</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>

                    <Card.Body className="d-flex flex-wrap justify-content-center">
                        {
                            aba === 'Rascunho' ?
                                Concurso.Datasource.Lista.filter(concurso => concurso.STATUS === EConcursoStatus.Rascunho).length > 0 ?
                                    <TabelaMais 
                                        colunas={Concurso.Grid.ListaRascunho} 
                                        dados={Concurso.Datasource.Lista.filter(concurso => concurso.STATUS === EConcursoStatus.Rascunho)} 
                                        handleClick={(e : IConcurso) => Concurso.Navegar(String(e.CD_CONCURSO))} 
                                    />
                                :
                                    'Não há nenhum concurso em rascunho pendente de processamento'
                            :
                                <TabelaMais 
                                    colunas={Concurso.Grid.ListaFinalizado} 
                                    dados={Concurso.Datasource.Lista.filter(concurso => concurso.STATUS === EConcursoStatus.Finalizado)} 
                                    handleClick={(e : IConcurso) => Concurso.Navegar(String(e.CD_CONCURSO))} 
                                />
                        }                        
                    </Card.Body>
                </Card>
            </Tab.Container>

            <FormRodape>
                <Button variant="success" size="lg" onClick={() => Concurso.Navegar('-1')}><TiPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>        
    )
}

export default ConcursoLista;