import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { IUseConcursoController } from "../../../../Controllers/Concurso";
import { IReactTable_Grid } from "../../../../Interfaces";
import { IConcurso } from "../../../../Interfaces/Concurso";
import { FaCloudArrowUp } from "react-icons/fa6";

interface Props {
    Controller : IUseConcursoController;
}

const useConcursoGrid = ({Controller} : Props) : IUseConcursoGrid => {
    const ListaFinalizado = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_Concurso",
            Header: "Concurso",
            accessor: "CD_CONCURSO"
        },  
        {
            id: "Concurso",
            Header: "Nome",
            accessor: "NM_CONCURSO"
        },   
        {
            id: "DT_CADASTRO",
            Header: "Cadastrado em",
            accessor: "DT_CADASTRO"
        },   
        {
            id: "DT_CONCURSO",
            Header: "Data do Concurso",
            accessor: "DT_CONCURSO"
        }, 
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : IConcurso = row.row.original;
                            if (window.prompt(`Deseja excluir o Concurso ${selecionado.NM_CONCURSO}? Isso irá desprocessar os resultados nos jogos desse concurso. Digite "SIM" para confirmar.`)?.toLowerCase() === 'sim') {
                                Controller.Deletar.mutate(selecionado);
                            }
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        }
    ], [Controller.Deletar]);

    const ListaRascunho = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_Concurso",
            Header: "Concurso",
            accessor: "CD_CONCURSO"
        },  
        {
            id: "Concurso",
            Header: "Nome",
            accessor: "NM_CONCURSO"
        },   
        {
            id: "DT_CADASTRO",
            Header: "Cadastrado em",
            accessor: "DT_CADASTRO"
        },   
        {
            id: "DT_CONCURSO",
            Header: "Data do Concurso",
            accessor: "DT_CONCURSO"
        }, 
        {
            id: "Acao",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        className="me-1"
                        variant="warning"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : IConcurso = row.row.original;
                            if (window.confirm(`Deseja processar o Concurso ${selecionado.NM_CONCURSO}? Isso irá marcar a pontuação dos jogadores.`)) {
                                Controller.Processar.mutateAsync(selecionado, {
                                    onSuccess: () => {
                                        alert('Concurso processado com sucesso!')
                                    }
                                });
                            }
                        }}
                    >
                        <FaCloudArrowUp style={{zoom: 1.4}} />
                    </Button>

                    <Button 
                        className="ms-1"
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : IConcurso = row.row.original;
                            if (window.confirm(`Deseja excluir o Concurso ${selecionado.NM_CONCURSO}?`)) {
                                Controller.Deletar.mutate(selecionado);
                            }
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        }
    ], [Controller.Deletar, Controller.Processar]);

    return {
        ListaFinalizado   : ListaFinalizado,
        ListaRascunho     : ListaRascunho
    }
}

export interface IUseConcursoGrid {
    ListaFinalizado   : IReactTable_Grid;
    ListaRascunho     : IReactTable_Grid;
}

export default useConcursoGrid;