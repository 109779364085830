import { Badge, Card } from "react-bootstrap"
import { EStatus, IJogo } from "../../Interfaces/Jogo";
import { ComissaoIgnora, JogoStatus } from "../../Constants/jogo";
import useMask from "../useMask";
import Countdown from "./countdown";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Jogo: IJogo;
}

const JogoCard = ({Jogo, ...rest} : Props) => {
    const formatter = useMask();
    const TotPcePremios = Jogo.COMISSAO
        ?.filter(comissao => !ComissaoIgnora.includes(comissao.TIPO))
        .reduce((acc, comissao) => acc + (comissao.PCE_PERCENTUAL ?? 0), 0);
    const QtdAposta = (Jogo.QTD_APOSTAS ?? 0);
    const VlrAposta = (Jogo.VLR_APOSTA ?? 0);
    const TotAposta = ((QtdAposta * VlrAposta) * ((TotPcePremios ?? 0) / 100));
    const QtdPremio = Jogo.COMISSAO && Jogo.COMISSAO.length > 0
        ? Jogo.COMISSAO.filter(comissao => !ComissaoIgnora.includes(comissao.TIPO)).length
        : 0;

    return (
        <div className="m-3" style={{cursor: 'pointer', userSelect: 'none'}} {...rest}>
            <Card>
                <Card.Header className="d-flex w-100 justify-content-center">
                    {Jogo.NOME}
                </Card.Header>

                <Card.Body className="d-flex w-100 flex-wrap justify-content-center">
                    <div className="d-flex w-100 justify-content-center">
                        {(() => {
                            switch (Jogo.STATUS) {
                            case EStatus.Aberto:
                                return <h5><Badge bg="success">Vendas Abertas!</Badge></h5>
                            case EStatus.Andamento:
                                return <h5><Badge bg="primary">{JogoStatus.find(status => status.STATUS === EStatus.Andamento)?.DESCRICAO}</Badge></h5>
                            case EStatus.Cancelado:
                                return <h5><Badge bg="danger">{JogoStatus.find(status => status.STATUS === EStatus.Cancelado)?.DESCRICAO}</Badge></h5>
                            case EStatus.Finalizado:
                                return <h5><Badge bg="dark">{JogoStatus.find(status => status.STATUS === EStatus.Finalizado)?.DESCRICAO}</Badge></h5>
                            default:
                                return <h5><Badge bg="warning">{JogoStatus.find(status => status.STATUS === EStatus.Rascunho)?.DESCRICAO}</Badge></h5>
                            }
                        })()}
                    </div>

                    <div className="d-flex w-100 justify-content-center">
                        {`Apenas ${formatter.setMaskMoney(Jogo.VLR_APOSTA ?? 0, 'R$')}`}
                    </div>

                    {
                        QtdPremio > 0 ?
                            <>
                                <div className="d-flex w-100 justify-content-center">
                                    {QtdPremio > 1 ? `${QtdPremio} Premiações` : `${QtdPremio} Premiação`}
                                </div>

                                <div className="d-flex w-100 justify-content-center">
                                    <h4><Badge bg="light" text="success">{formatter.setMaskMoney(TotAposta, 'R$')}</Badge></h4>
                                </div>
                            </>
                        :
                            null
                    }                
                </Card.Body>

                {
                    Jogo.DT_INICIO && Jogo.DT_INICIO !== "0000-00-00" ?
                        <Card.Footer className="d-flex w-100 justify-content-center">
                            <Countdown DT_INICIO={String(Jogo.DT_INICIO)} STATUS={Jogo.STATUS} />
                        </Card.Footer>
                    :
                        <></>
                }            
            </Card>
        </div>
    )
}

export default JogoCard;