import { EStatus, ETipo } from "../Interfaces/Jogo";

export const JogoStatus = [
    {
        STATUS: EStatus.Aberto,
        DESCRICAO: 'Em Aberto'
    },
    {
        STATUS: EStatus.Andamento,
        DESCRICAO: 'Em Andamento'
    },
    {
        STATUS: EStatus.Cancelado,
        DESCRICAO: 'Cancelado'
    },
    {
        STATUS: EStatus.Finalizado,
        DESCRICAO: 'Finalizado'
    },
    {
        STATUS: EStatus.Rascunho,
        DESCRICAO: 'Rascunho'
    }
]

export const JogoComissaoTipo = [
    {
        TIPO: ETipo.ComissaoInterna,
        DESCRICAO: 'Comissão Interna'
    },
    {
        TIPO: ETipo.Ganhador,
        DESCRICAO: 'Ganhador'
    },
    {
        TIPO: ETipo.MenorPontuacao,
        DESCRICAO: 'Menor Pontuação'
    },
    {
        TIPO: ETipo.Outros,
        DESCRICAO: 'Outros Prêmios'
    },
    {
        TIPO: ETipo.ComissaoCambista,
        DESCRICAO: 'Comissão Cambista'
    }
]

export const ComissaoIgnora = ['CI', 'CC'];