import { UseMutationResult, useMutation } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import { IPremio } from "../Interfaces/Premio";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    enableQuery?    : boolean;
}

const usePremioController = ({updateQueryKey, isArray = true, enableQuery = true} : Props) : IUsePremioController => {
    const API = useAPI();

    const primaryKey = 'CD_JOGO_PULE_APOSTA';
    const endpoint = 'premio';

    const Deletar = useMutation(
        (data : DeepPartial<IPremio>) => 
            API.delBody(`${endpoint}/deletar/${data[primaryKey]}`, data, false)
        , {
            onSuccess: (ret: DeepPartial<IPremio>, data: DeepPartial<IPremio>) => {
                
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IPremio>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: any, data: DeepPartial<IPremio>) => {
            
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Cadastrar   : Cadastrar,
        Deletar     : Deletar
    }
}

export interface IUsePremioController {
    Cadastrar   : UseMutationResult<DeepPartial<IPremio>, any, DeepPartial<IPremio>, unknown>;
    Deletar     : UseMutationResult<DeepPartial<IPremio>, any, DeepPartial<IPremio>, unknown>;
}

export default usePremioController;