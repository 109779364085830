import { useContext, useEffect } from "react";
import useApostaController, { IUseApostaController } from "../../../../Controllers/Aposta"
import useApostaDatasource, { IUseApostaDatasource } from "./datasource";
import { CtxAppParams } from "../../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../../Constants/permissao";

const useAposta = (cdJogo?: string) : IUseAposta => {
    const AppParams = useContext(CtxAppParams);
    const AlteraAposta = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.ALTERA_PULE));
    const Gerencial = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE));
    const Controller = useApostaController({
        updateQueryKey: 'Jogo',
        enableQuery: cdJogo === undefined,
        queryValue: (cdJogo) && (Number(cdJogo) > 0) ? cdJogo : undefined,
        enableCambista: AlteraAposta,
        enableGerencial: Gerencial
    });
    const Datasource = useApostaDatasource({Controller});

    useEffect(() => {
        if (cdJogo && Number(cdJogo) > 0 && Gerencial) {
            Controller.ListarTodosJogo.refetch();
        }
    }, [cdJogo]); //eslint-disable-line

    return {
        Controller: Controller,
        Datasource: Datasource
    }
}

export interface IUseAposta {
    Controller: IUseApostaController;
    Datasource: IUseApostaDatasource;
}

export default useAposta;