import { createContext } from "react";
import { IUsePremioController } from "../Controllers/Premio";
import { IJogoComissao } from "../Interfaces/Jogo";

export interface ICtxPremioAposta {
    ControllerPremio?: IUsePremioController;
    Comissao?: IJogoComissao;
}

export const CtxPremioAposta = createContext<ICtxPremioAposta>({
    ControllerPremio: undefined,
    Comissao: undefined
} as ICtxPremioAposta);