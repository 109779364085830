import { useContext, useState } from "react";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";
import { Form, InputGroup } from "react-bootstrap";
import { IPessoa } from "../../../../Interfaces/Pessoa";
import useCidadeEstado from "../../../../Components/useEstadoCidade";

interface Props {
    apostador       : IPessoa;
    setApostador    : React.Dispatch<React.SetStateAction<IPessoa>>;
}

const CadastraApostadorE2B = ({apostador, setApostador} : Props) => {
    const {formatter} = useContext(CtxApostaCompra);
    const [uf, setUF] = useState<string>('');
    const CidadeEstado = useCidadeEstado(uf);

    return (
        <div>
            <Form.Group className="mt-3">
                <Form.Label>Celular:</Form.Label>
                {/* <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} /> */}
                <InputGroup>
                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                    <Form.Control 
                        name={'CELULAR'}
                        type={'text'}
                        value={apostador.CELULAR}
                        onChange={e => {
                            const newValue = formatter.setMaskPhone(e.target.value);
                            setApostador(prev => ({...prev, CELULAR: newValue}));
                        }}
                        size="lg"
                        placeholder="Celular..." 
                    />
                </InputGroup>
            </Form.Group>     

            <Form.Group className="mt-3">
                <Form.Label>Nome Completo:</Form.Label>
                <InputGroup>
                    <Form.Control 
                        name={'NOME'}
                        type={'text'}
                        autoComplete="off"
                        value={apostador.NOME}
                        onChange={e => {
                            setApostador(prev => ({...prev, NOME: e.target.value}));
                        }}
                        size="lg"
                        placeholder="Nome completo..." 
                    />
                </InputGroup>
            </Form.Group>    

            <Form.Group className="mt-3">
                <Form.Label>Estado (UF):</Form.Label>
                    <Form.Select 
                        name={'UF'}
                        value={apostador.UF}
                        onChange={ e => {
                            setApostador(prev => ({...prev, UF: e.target.value}));
                            setUF(e.target.value);
                        }}
                        size="lg"
                    >
                    <option value={undefined}>Selecione uma UF</option>
                    {CidadeEstado.Estados.map((index: string) => (
                        <option key={index} value={index}>
                            {index}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>   

            <Form.Group className="mt-3">
                <Form.Label>Cidade:</Form.Label>
                    <Form.Select
                        name={'CIDADE'}
                        value={apostador.CIDADE}
                        onChange={ e => {
                            setApostador(prev => ({...prev, CIDADE: e.target.value}));
                        }}
                        size="lg"
                        disabled={uf === ''}
                    >
                    <option value={undefined}>Selecione uma cidade</option>
                    {CidadeEstado.Cidades.map((index: string) => (
                        <option key={index} value={index}>
                            {index}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>     
        </div>
    )
}

export default CadastraApostadorE2B;