import { useEffect, useState } from "react";
import { CtxAppParams } from "./Contexts/ctxAppParams";
import { QueryClient, QueryClientProvider } from "react-query";
import useApp from "./Components/useApp";
import Rotas from "./routes";
import useAutenticacao from "./Components/useAuth";
import { IPessoaAcesso } from "./Interfaces/Pessoa";
import { IPagina } from "./Constants/paginas";
import { ToastContainer } from "react-toastify";

const queryDefault = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 300000, // Cache 5 miuntos
			retryDelay: 5000, // 5 Segundos para dar um retry em caso de erro
			refetchOnMount: false,
		},
	},
});

function App() {
	const Aplicativo = useApp();
	const {getToken} = useAutenticacao()
	const [SessaoExpirada, setSessaoExpirada] = useState<boolean>(false);
	const [Token, setToken] = useState<string | null | undefined>(getToken);
	const [Permissoes, setPermissoes] = useState<IPessoaAcesso[] | null | undefined>(undefined);
	const [PaginaAtual, setPaginaAtual] = useState<IPagina | null | undefined>(undefined);

	useEffect(() => {
		if(SessaoExpirada) {
			alert('Sessão expirada');
            Aplicativo.Deslogar();
		}
	}, [SessaoExpirada]); // eslint-disable-line

	return (
		<CtxAppParams.Provider value={{
			SessaoExpirada, setSessaoExpirada,
			Token, setToken,
			Permissoes, setPermissoes,
			PaginaAtual, setPaginaAtual
		}}>
			<ToastContainer
				position="bottom-center"
				autoClose={3000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<QueryClientProvider client={queryDefault}>
				<Rotas />
			</QueryClientProvider>
		</CtxAppParams.Provider>
	);
}

export default App;
