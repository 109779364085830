import { useContext } from "react";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";
import { useMemo } from "react";
import Tabela from "../../../../Components/tabela";
import { IPessoa } from "../../../../Interfaces/Pessoa";
import { Form } from "react-bootstrap";

interface Props {
    listaApostador  : IPessoa[];
    setEstagio      : React.Dispatch<React.SetStateAction<"2" | "3" | "1" | "2A" | "2B">>;
    setApostador    : React.Dispatch<React.SetStateAction<IPessoa>>;
}

const SelecionaApostadorE2A = ({listaApostador, setEstagio, setApostador} : Props) => {
    const {formatter} = useContext(CtxApostaCompra);

    const dados = useMemo(() => listaApostador, [listaApostador]);
    const colunas = useMemo(() => [
        {
            Header: 'Nome',
            id: 'nome',
            accessor: 'NOME'
        },
        {
            Header: 'Celular',
            id: "celular",
            Cell: (row: any) : any => formatter.setMaskPhone(row.row.original['CELULAR'])
        }        
    ], [formatter]);    

    return (
        <div>
            <Tabela 
                dados={dados}
                colunas={colunas} 
                handleClick={e => {
                    setApostador(e);
                    setEstagio('3');
                }}
            />

            <Form.Text 
                onClick={() => setEstagio('2B')}
                style={{cursor: 'pointer'}}
            >
                Não encontrou o apostador? Clique aqui para cadastrar.
            </Form.Text>
        </div>
    )
}

export default SelecionaApostadorE2A;