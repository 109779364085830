import { useContext, useMemo, useState } from "react";
import ModalConfirmacao from "../../../../Components/ModalConfirmacao";
import { EPermissao } from "../../../../Constants/permissao";
import { IPessoa } from "../../../../Interfaces/Pessoa";
import usePessoaController from "../../../../Controllers/Pessoa";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";
import ConfirmacaoE1 from "./confirmacaoE1";
import VinculaApostadorE2 from "./vinculaApostadorE2";
import SelecionaApostadorE2A from "./selecionaApostadorE2A";
import CadastraApostadorE2B from "./cadastraApostadorE2B";
import { IGenericInformation } from "../../../../Interfaces";
import StepsIndicator from "../../../../Components/formularios/StepsIndicator";
import { v4 as uuid } from "uuid";

const JogoApostaCompra = () => {
    const {Jogo, formatter, exibeCarrinho, setExibeCarrinho, AppParams, Carrinho} = useContext(CtxApostaCompra);
    const { Buscar, VerificarCadastro, BuscarNome, Cadastrar } = usePessoaController('Pessoa', true, false);
    const cambista = AppParams.Permissoes?.find(permissao => permissao.REGRA === EPermissao.ALTERA_PULE);
    
    const estagioDefault = cambista ? '2' : '1';
    const vinculaApostadorDefault = [
        {
            CHAVE: 'CELULAR',
            VALOR: ''
        },
        {
            CHAVE: 'NOME',
            VALOR: ''
        }
    ];
    
    const [estagio, setEstagio] = useState<'1' | '2' | '2A' | '2B' | '3'>(estagioDefault);
    const [apostador, setApostador] = useState<IPessoa>({} as IPessoa);
    const [selecaoApostador, setSelecaoApostador] = useState<IPessoa[]>([]);
    const [vinculaApostador, setVinculaApostador] = useState<IGenericInformation[]>(vinculaApostadorDefault);

    const conteudo = useMemo(() => {
        switch (estagio) {
            case "2"    : return <VinculaApostadorE2 vinculaApostador={vinculaApostador} setVinculaApostador={setVinculaApostador} />;
            case "2A"   : return <SelecionaApostadorE2A setApostador={setApostador} setEstagio={setEstagio} listaApostador={selecaoApostador} />;
            case "2B"   : return <CadastraApostadorE2B apostador={apostador} setApostador={setApostador} />;
            default     : return <ConfirmacaoE1 apostador={apostador} />;
        }
    }, [estagio, vinculaApostador, apostador, selecaoApostador]);

    return (
        <ModalConfirmacao
            titulo={cambista ? 'Finalizando venda de aposta' : 'Finalizando compra de aposta'}
            show={exibeCarrinho} 
            confirmText={['2', '2A', '2B'].includes(estagio) ? 'Continuar' : undefined}
            cancelText={['3', '2A', '2B'].includes(estagio) ? 'Voltar' : undefined}
            disabledConfirm={
                estagio === '2A' ||
                BuscarNome.isLoading ||
                VerificarCadastro.isLoading ||
                Buscar.isLoading ||
                Jogo.Aposta.Controller.Cadastrar.isLoading
            }            
            onHide={() => {
                if (['2A', '2B', '3'].includes(estagio)) {
                    setEstagio('2');
                    setApostador({} as IPessoa);
                } else {
                    setExibeCarrinho(false); 
                    setEstagio(estagioDefault);
                }                
            }}
            onSubmitHandler={() => {
                if (estagio === '1') { // Confirmação do usuario
                    Jogo.Aposta.Controller.Cadastrar.mutateAsync({
                        CD_JOGO: Jogo.Datasource.Item.CD_JOGO,
                        CD_APOSTADOR: -1,
                        CD_CAMBISTA: null,
                        APOSTA: Carrinho.Read!.map(aposta => ({LANCAMENTO: aposta}))
                    }, {
                        onSuccess: (retAposta) => {
                            Jogo.Aposta.Controller.ListarUsuarioJogo.refetch();
                            alert(`Aposta cadastrada com sucesso, o número da sua pule para identificação é: ${retAposta.CD_JOGO_PULE}`);
                            Carrinho.Write([]);
                            setExibeCarrinho(false);
                        }
                    })
                } else if (estagio === '2') { // Procurar celular ou nome
                    const tipoBusca = vinculaApostador.find(x => String(x.VALOR ?? "") !== '')?.CHAVE;
                    const valorBusca = tipoBusca === 'CELULAR' 
                        ? formatter.getPhone(vinculaApostador.find(x => x.CHAVE === tipoBusca)?.VALOR ?? "") 
                        : (vinculaApostador.find(x => x.CHAVE === tipoBusca)?.VALOR ?? "");

                    if (tipoBusca === 'CELULAR') {
                        if (valorBusca?.length === 11) {
                            VerificarCadastro.mutateAsync({CELULAR: valorBusca}, {
                                onSuccess: (ret: any) => {
                                    if (ret["EXISTE"]) {
                                        Buscar.mutateAsync({CELULAR: valorBusca}, { // Busca cliente já existente para confirmação de aposta
                                            onSuccess: (ret: IPessoa) => {
                                                setApostador(ret);
                                                setEstagio('3');
                                            }
                                        })
                                    } else { // Caso não encontrado, cadastrar
                                        setApostador({CELULAR: formatter.setMaskPhone(valorBusca)} as IPessoa);
                                        setEstagio('2B');                                        
                                    }
                                }
                            })                            
                        } else {
                            alert('Celular informado inválido.');
                        }
                    } else if (tipoBusca === 'NOME') {
                        if (valorBusca?.length >= 3) {
                            BuscarNome.mutateAsync({NOME: valorBusca}, {
                                onSuccess: (ret: IPessoa[]) => {
                                    if (ret.length > 0) { // Selecionar resultados
                                        setSelecaoApostador(ret);
                                        setEstagio('2A');
                                    } else { // Cadastrar
                                        if (window.confirm('Não foi encontrado nenhum apostador cadastrado com esse nome, deseja cadastrar?')) {
                                            setApostador({NOME: valorBusca} as IPessoa);
                                            setEstagio('2B');
                                        }
                                    }                                    
                                }
                            })
                        } else {
                            alert('Digite um nome válido');
                        }
                    } else {
                        alert('Insira o celular ou o nome do apostador para poder fechar a venda.');
                    }
                } else if (estagio === '2B') { // Cadastrar apostador
                    const dados = Object.assign({}, apostador);
                    dados.CELULAR = formatter.getPhone(dados.CELULAR);

                    if (dados.CELULAR.length !== 11) {
                        alert('Celular deve ser válido');
                    } else if ((dados.NOME ?? "").trim().length <= 3) {
                        alert('Nome deve ser válido');
                    } else if ((dados.UF ?? "").length !== 2) {
                        alert('Estado deve ser preenchido');
                    } else if ((dados.CIDADE ?? "").length < 2) {
                        alert('Cidade deve ser preenchida');
                    } else {
                        Cadastrar.mutateAsync({...dados, SENHA: uuid.toString()}, {
                            onSuccess: (ret: IPessoa) => {
                                setApostador(prev => ({...prev, CD_PESSOA: ret.CD_PESSOA}));
                                setEstagio('3');
                            }
                        })
                    }
                } else if (estagio === '3') { // Confirmação cambista
                    Jogo.Aposta.Controller.Cadastrar.mutateAsync({
                        CD_JOGO: Jogo.Datasource.Item.CD_JOGO,
                        CD_APOSTADOR: apostador.CD_PESSOA,
                        CD_CAMBISTA: -1,
                        APOSTA: Carrinho.Read!.map(aposta => ({LANCAMENTO: aposta}))
                    }, {
                        onSuccess: (retAposta) => {
                            alert(`Aposta cadastrada com sucesso, o número da sua pule para identificação é: ${retAposta.CD_JOGO_PULE}`)
                            Carrinho.Write([]);
                            setVinculaApostador(vinculaApostadorDefault);
                            setExibeCarrinho(false);
                            Jogo.Aposta.Controller.ListarCambistaJogo.refetch();
                        }
                    })
                } else {
                    alert('Erro na etapa de confirmação da aposta, favor encaminhar aos responsáveis técnicos.');
                }
            }}
        >
            <div>
                {cambista && <StepsIndicator
                    className="mb-3 d-flex justify-content-center"
                    currentStep={
                        (() => {
                            switch (estagio) {
                                case "1": return 1;
                                case "2": return 1;
                                case "3": return 3;                                
                                default: return 2;
                            }
                        })()
                    }
                    totalSteps={cambista ? 3 : 1}
                />}
                {conteudo}
            </div>
        </ModalConfirmacao>
    )
}

export default JogoApostaCompra;