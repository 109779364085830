import { Outlet, useOutletContext } from "react-router-dom";
import { IUseJogo } from "./Hook/useJogo";
import { Container, Nav, Tab } from "react-bootstrap";
import { useContext } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { EStatus } from "../../Interfaces/Jogo";
import { EPermissao } from "../../Constants/permissao";

const JogoDetalhe = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const AppParams = useContext(CtxAppParams);
    const PaginaDefault = Jogo.AlteraJogo 
        ? 'JogoCadastro' 
        : Jogo.Datasource.Item.STATUS === EStatus.Aberto 
            ? 'JogoApostaCadastro'
            : 'JogoResultado'

    return (
        <Container className={"verticalCenter"}>
            <Tab.Container 
                activeKey={AppParams.PaginaAtual?.Alias}
                onSelect={(eventKey) => Jogo.NavegaPagina(eventKey ?? PaginaDefault, ['cdJogo'], [Jogo.Codigo!])} 
            >
                <Nav variant="tabs" className="d-flex justify-content-between" style={{width: '100%'}}>
                    {Jogo.Datasource.Item.STATUS === EStatus.Aberto && <Nav.Item>
                        <Nav.Link eventKey="JogoApostaCadastro">Fazer Aposta</Nav.Link>
                    </Nav.Item>}
                    <Nav.Item>
                        <Nav.Link eventKey="JogoResultado">Resultados</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="JogoApostaLista">Minhas Apostas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="JogoPremio">Prêmios e Informações</Nav.Link>
                    </Nav.Item>
                    {Jogo.AlteraJogo && <Nav.Item>
                        <Nav.Link eventKey="JogoCadastro">Manutenção</Nav.Link>
                    </Nav.Item>}
                    {AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.ALTERA_PULE)) && <Nav.Item>
                        <Nav.Link eventKey="JogoApostaConfirma">Confirmação de Apostas</Nav.Link>
                    </Nav.Item>}
                    {AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE)) && <Nav.Item>
                        <Nav.Link eventKey="JogoGerenciador">Gerenciamento</Nav.Link>
                    </Nav.Item>}
                </Nav>

                <Outlet context={Jogo} />
            </Tab.Container>
        </Container>
    );
}

export default JogoDetalhe;