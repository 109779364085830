import Aposta from "../Pages/Aposta";
import ApostaLista from "../Pages/Aposta/lista";
import Concurso from "../Pages/Concurso";
import ConcursoCadastro from "../Pages/Concurso/cadastro";
import ConcursoLista from "../Pages/Concurso/lista";
import Controle from "../Pages/Controle";
import ControleCadastro from "../Pages/Controle/cadastro";
import ControleLista from "../Pages/Controle/lista";
import Jogo from "../Pages/Jogo";
import JogoAposta from "../Pages/Jogo/Aposta";
import JogoApostaConfirma from "../Pages/Jogo/Aposta/confirmacao";
import JogoApostaUsuario from "../Pages/Jogo/Aposta/listaUsuario";
import JogoCadastro from "../Pages/Jogo/Cadastro";
import JogoDetalhe from "../Pages/Jogo/detalhe";
import JogoGerenciador from "../Pages/Jogo/Gerenciador";
import JogoLista from "../Pages/Jogo/lista";
import JogoPremioInfo from "../Pages/Jogo/PremioInfo";
import JogoResultado from "../Pages/Jogo/Resultado";
import TipoJogo from "../Pages/TipoJogo";
import TipoJogoCadastro from "../Pages/TipoJogo/cadastro";
import TipoJogoLista from "../Pages/TipoJogo/lista";

export interface IPagina {
    Id          : number;
    Alias       : string;
    Description : string;
    Path        : string;
    Element     : JSX.Element;
    Index?      : JSX.Element;
    Permissions?: string[];
    Pages       : IPagina[];
};

export const Paginas : IPagina[] = [
    {
        Id      : 0,
        Alias   : 'Jogo',
        Description: 'Jogos',
        Path    : '/jogo',
        Element : <Jogo />,
        Index   : <JogoLista />,
        Pages   : [
            {
                Id      : 0,
                Alias   : 'JogoDetalhe',
                Description: 'Jogo',
                Path    : 'detalhe/:cdJogo?',
                Element : <JogoDetalhe />,
                Pages   : [
                    {
                        Id      : 0,
                        Alias   : 'JogoApostaCadastro',
                        Description: 'Fazer aposta',
                        Path    : 'apostaCadastro',
                        Element : <JogoAposta />,
                        Pages   : []
                    },
                    {
                        Id      : 1,
                        Alias   : 'JogoApostaLista',
                        Description: 'Minhas Apostas',
                        Path    : 'apostaLista',
                        Element : <JogoApostaUsuario />,
                        Pages   : []
                    },
                    {
                        Id      : 2,
                        Alias   : 'JogoResultado',
                        Description: 'Resultados',
                        Path    : 'resultado',
                        Element : <JogoResultado />,
                        Pages   : []
                    },
                    {
                        Id      : 3,
                        Alias   : 'JogoPremio',
                        Description: 'Prêmios e Informações',
                        Path    : 'premioinfo',
                        Element : <JogoPremioInfo />,
                        Pages   : []
                    },
                    {
                        Id      : 4,
                        Alias   : 'JogoCadastro',
                        Description: 'Cadastro de Jogo',
                        Path    : 'cadastro',
                        Element : <JogoCadastro />,
                        Permissions: ['ALTERA_JOGO'],
                        Pages   : []
                    },
                    {
                        Id      : 5,
                        Alias   : 'JogoApostaConfirma',
                        Description: 'Confirmação de Apostas',
                        Path    : 'apostaConfirmacao',
                        Element : <JogoApostaConfirma />,
                        Permissions: ['ALTERA_PULE'],
                        Pages   : []
                    },
                    {
                        Id      : 6,
                        Alias   : 'JogoGerenciador',
                        Description: 'Gerenciamento do Jogo',
                        Path    : 'jogoGerenciador',
                        Element : <JogoGerenciador />,
                        Permissions: ['GERENCIA_PULE'],
                        Pages   : []
                    }
                ]
            }
        ]
    },
    {
        Id      : 1,
        Alias   : 'Controle',
        Description: 'Permissões de Usuário',
        Path    : '/controle',
        Element : <Controle />,
        Index   : <ControleLista />,
        Permissions: ['ALTERA_CONTROLE'],
        Pages   : [
            {
                Id      : 0,
                Alias   : 'ControleCadastro',
                Description: 'Cadastro de Controle',
                Path    : 'cadastro/:cdControle?',
                Element : <ControleCadastro />,
                Pages   : []
            },
        ]
    },
    {
        Id      : 2,
        Alias   : 'TipoJogo',
        Description: 'Tipos de Jogo',
        Path    : '/tipojogo',
        Element : <TipoJogo />,
        Index   : <TipoJogoLista />,
        Permissions: ['ALTERA_TIPO_JOGO'],
        Pages   : [
            {
                Id      : 0,
                Alias   : 'TipoJogoCadastro',
                Description: 'Cadastro de Tipo de Jogo',
                Path    : 'cadastro/:cdTipoJogo?',
                Element : <TipoJogoCadastro />,
                Pages   : []
            },
        ]
    },
    {
        Id      : 3,
        Alias   : 'Concurso',
        Description: 'Concursos',
        Path    : '/concurso',
        Element : <Concurso />,
        Index   : <ConcursoLista />,
        Permissions: ['ALTERA_CONCURSO'],
        Pages   : [
            {
                Id      : 0,
                Alias   : 'ConcursoCadastro',
                Description: 'Cadastro de Concurso',
                Path    : 'cadastro/:cdConcurso?',
                Element : <ConcursoCadastro />,
                Pages   : []
            },
        ]
    },
    {
        Id      : 4,
        Alias   : 'Aposta',
        Description: 'Minhas Apostas',
        Path    : '/aposta',
        Element : <Aposta />,
        Index   : <ApostaLista />,
        Permissions: [],
        Pages   : []
    },
];