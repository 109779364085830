import { useContext, useLayoutEffect, useMemo, useState } from "react";
import FormRodape from "../../../Components/formularios/FormRodape";
import useMask from "../../../Components/useMask";
import { Button, Card, Col, Container } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import CartaoDezena from "../../../Components/aposta/cartaoDezena";
import CartaoAposta from "../../../Components/aposta/cartaoAposta";
import useStateStorage from "../../../Components/useStateStorage";
import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import JogoApostaCompra from "./Compra";
import { CtxApostaCompra } from "../../../Contexts/ctxApostaCompra";
import { EPermissao } from "../../../Constants/permissao";
import { FaArrowRotateRight } from "react-icons/fa6";
import JogoApostaRepete from "./Repete";

const JogoAposta = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const formatter = useMask();
    const [carregamento, setCarregamento] = useState<boolean>(true);
    const [selecionado, setSelecionado] = useState<Array<number>>([]);
    const Carrinho = useStateStorage<Array<number[]> | undefined>(`carrinho${Jogo.Codigo}`, []);
    const AppParams = useContext(CtxAppParams);
    const [exibeCarrinho, setExibeCarrinho] = useState<boolean>();

    const [exibeRepete, setExibeRepete] = useState<boolean>(false); 
    const cambista = AppParams.Permissoes?.find(permissao => permissao.REGRA === EPermissao.ALTERA_PULE);
    const carrinhoVazio = useMemo(() => <div>Nenhuma aposta no carrinho</div>, []);

    useLayoutEffect(() => {
        if (Jogo.ListaTipoJogo.isFetched && Jogo.ListaTipoJogo.isSuccess && Jogo.ListaTipoJogo.data && Jogo.TipoJogo !== undefined) {
            setCarregamento(false);
        } else if (Jogo.ListaTipoJogo.isError) {
            throw 'Erro ao carregar TipoJogo: ' + Jogo.ListaTipoJogo.error // eslint-disable-line
        }
    }, [Jogo.ListaTipoJogo.data, Jogo.ListaTipoJogo.isFetched, Jogo.ListaTipoJogo.isSuccess, Jogo.TipoJogo, Jogo.ListaTipoJogo.isError, Jogo.ListaTipoJogo.error]);

    const repeticaoAposta = useMemo(() => {
        return (
            <div className="d-flex align-items-center flex-column">
                {carrinhoVazio}
                <Button 
                    variant="primary" 
                    size="sm" 
                    onClick={() => {
                        setExibeRepete(true);
                    }}
                ><FaArrowRotateRight /> Repetir aposta</Button>
            </div>
        )
    }, [carrinhoVazio])

    if (!carregamento) {
        return (
            <Container className="verticalCenter">
                <CtxApostaCompra.Provider value={{
                    AppParams: AppParams,
                    Carrinho: Carrinho,
                    Jogo: Jogo,
                    exibeCarrinho: exibeCarrinho,
                    setExibeCarrinho: setExibeCarrinho,
                    formatter: formatter,
                    exibeRepete: exibeRepete,
                    setExibeRepete: setExibeRepete
                
                }}>
                    <JogoApostaCompra />    
                    <JogoApostaRepete />
                </CtxApostaCompra.Provider>                        

                <Col lg={8}>
                    <div className="w-100 py-3 px-2 d-flex flex-wrap">
                        <div className="w-100 d-flex">
                            <h3>Preencha sua aposta</h3>
                        </div>

                        <div className="w-100 d-flex">
                            <CartaoDezena 
                                VlrMin={Jogo.TipoJogo.VLR_MIN} 
                                VlrMax={Jogo.TipoJogo.VLR_MAX}
                                QtdMin={Jogo.TipoJogo.QTD_MIN} 
                                QtdMax={Jogo.TipoJogo.QTD_MAX} 
                                Selecionado={selecionado}
                                setSelecionado={setSelecionado}
                                Carrinho={Carrinho.Read}
                                setCarrinho={Carrinho.Write}                            
                            />
                        </div>

                        
                    </div>
                </Col>
                <Col lg={4}>
                    <Card className="card my-3 mx-2 w-100">
                        <Card.Header>
                            Apostas no carrinho
                        </Card.Header>

                        <Card.Body className="d-flex flex-wrap justify-content-center">
                            <CartaoAposta
                                VlrMax={Jogo.TipoJogo.VLR_MAX}
                                Carrinho={Carrinho.Read}
                                setCarrinho={Carrinho.Write}     
                            >
                                {
                                    cambista
                                        ? repeticaoAposta
                                        : carrinhoVazio
                                }
                            </CartaoAposta>
                        </Card.Body>
                    </Card>
                </Col>
                <FormRodape>
                    <div className="container px-3 pt-2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'auto', gap: '0.1rem' }}>
                        <div className="total" style={{ gridRow: '1', gridColumn: '1/2' }}>
                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'bold' }}>
                                {`${formatter.setMaskMoney(Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length * Jogo.Datasource.Item.VLR_APOSTA : 0 , "R$")}`}
                            </p>
                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'normal' }}>
                                {`${Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length : 0} Aposta(s)`}
                            </p>
                        </div>
                        <div className="totalvalue" style={{ gridRow: '1', gridColumn: '3/3' }}>
                            <Button 
                                size="lg" 
                                variant="success" 
                                disabled={Carrinho.Read?.length === 0} 
                                onClick={() => setExibeCarrinho(true)}
                            >
                                <FaShoppingCart/> Comprar
                            </Button>
                        </div>
                    </div>
                </FormRodape>
            </Container>
        );
    } else {
        return (
            <>carregando</>
        )
    }
}

export default JogoAposta;