import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import { useCallback, useMemo, useState } from "react";
import useMask from "../../../Components/useMask";
import useColorScheme from "../../../Components/aposta/colorScheme";
import { Button, Card, Col, Container, Form, InputGroup, Nav, Tab } from "react-bootstrap";
import { FaPrint, FaSearch, FaTimes } from "react-icons/fa";
import CartaoPule from "../../../Components/pule/cartaoPule";
import { EPuleStatus, IPule } from "../../../Interfaces/Pule";
import Tabela from "../../../Components/tabela";
import PDFComprovantePule from "../../../Reports/comprovantePule";

const JogoApostaConfirma = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const [numeroPule, setNumeroPule] = useState<number>();
    const formatter = useMask();
    const Colors = useColorScheme();
    const [aba, setAba] = useState<'Pendente' | 'Confirmado'>('Pendente');

    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const Cartoes = useCallback((pule: IPule) => {
        return <CartaoPule
            Pule={pule}
            Jogo={Jogo.Datasource.Item}
            MaxDigits={maxDigits}
            formatter={formatter}
            Colors={Colors}
            ModoExibicao={"Confirmacao"}
        />
    }, [Jogo.Datasource.Item, maxDigits, formatter, Colors]);

    return (
        <Container className="verticalCenter">
            <div className="d-flex w-100 align-content-center">
                <Form.Group className="mt-3 w-100">
                    <Form.Label>Pesquisar pelo número da pule:</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="cerquilha">#</InputGroup.Text>
                        <Form.Control 
                            name={'NR_PULE'}
                            type={'number'}
                            value={numeroPule ?? ""}
                            onChange={(e) => setNumeroPule(Number(e.target.value))}
                            placeholder="Dígite o número da pule..." 
                            size="lg"
                        />
                        <Button 
                            onClick={() => {
                                if (!Jogo.Aposta.Controller.Buscar.isSuccess) {
                                    Jogo.Aposta.Controller.Buscar.mutateAsync({
                                        CD_JOGO_PULE: numeroPule
                                    }, {
                                        onSuccess: (retPesquisa) => {
                                            if (retPesquisa.CD_JOGO !== Jogo.Datasource.Item.CD_JOGO) {
                                                alert('Número da pule informada não pertence ao jogo atual: ' + Jogo.Datasource.Item.NOME);
                                                Jogo.Aposta.Controller.Buscar.reset();
                                            }
                                        }
                                    })
                                } else {
                                    Jogo.Aposta.Controller.Buscar.reset();
                                    setNumeroPule(undefined);
                                }
                            }}
                        >
                            {
                                !Jogo.Aposta.Controller.Buscar.isSuccess ?
                                    <><FaSearch /> Pesquisar</>
                                :
                                    <><FaTimes /> Limpar</>
                            }
                        </Button>
                    </InputGroup>                    
                </Form.Group>
            </div>
            
            <div className="d-flex w-100 justify-content-center flex-wrap">
                {
                    Jogo.Aposta.Controller.Buscar.isSuccess ?
                        <CartaoPule
                            Pule={Jogo.Aposta.Datasource.Item}
                            Jogo={Jogo.Datasource.Item}
                            MaxDigits={maxDigits}
                            formatter={formatter}
                            Colors={Colors}
                            ModoExibicao={"Confirmacao"}
                        />
                    :
                        <Col md={12}>
                            <Tab.Container
                                activeKey={aba}
                                onSelect={(eventKey) => setAba(eventKey as any)} 
                            >
                                <Card className="mt-3">
                                    <Card.Header>
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="Pendente">Pendentes</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Confirmado">Confirmados</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>

                                    <Card.Body className="d-flex w-100 justify-content-center flex-wrap">
                                        {
                                            aba === "Pendente" ?
                                                Jogo.Aposta.Datasource.ListaCambistaJogo && Jogo.Aposta.Datasource.ListaCambistaJogo?.length > 0 && 
                                                Jogo.Aposta.Datasource.ListaCambistaJogo.map(pule => {
                                                    if (pule.STATUS === EPuleStatus.Aberto) {
                                                        return (
                                                            <Col key={pule.CD_JOGO_PULE} md={6}>
                                                                {Cartoes(pule)}
                                                            </Col>
                                                        )
                                                    } else {
                                                        return undefined
                                                    }
                                                })
                                            :
                                                <Tabela 
                                                    dados={Jogo.Aposta.Datasource.ListaCambistaJogo.filter(f => f.STATUS === EPuleStatus.Finalizado)} 
                                                    colunas={[
                                                        {
                                                            Header: 'Pule',
                                                            id: "pule",
                                                            accessor: "CD_JOGO_PULE"
                                                        },
                                                        {
                                                            Header: 'Nome',
                                                            id: "nome",
                                                            accessor: "NOME"
                                                        },
                                                        {
                                                            Header: 'Apostas',
                                                            id: "apostas",
                                                            Cell: (row: any) => ((row.row.original as IPule).APOSTA?.length ?? 0)
                                                        },
                                                        {
                                                            id: "BtnExcluir",
                                                            Header: "",
                                                            Cell: (row: any) : any => 
                                                                <div className="w-100 d-flex justify-content-around">
                                                                    <Button 
                                                                        variant="warning"
                                                                        size="sm"
                                                                        onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            const selecionado : IPule = row.row.original;
                                                                            PDFComprovantePule({
                                                                                Jogo: Jogo.Datasource.Item, 
                                                                                Pule: selecionado, 
                                                                                MaxDigits: maxDigits, 
                                                                                formatter: formatter,
                                                                                url: window.location.origin
                                                                            });
                                                                        }}
                                                                    >
                                                                        <FaPrint />
                                                                    </Button>
                                                                </div>
                                                        }
                                                    ]} 
                                                />
                                        }
                                    </Card.Body>
                                </Card>
                            </Tab.Container>
                        </Col>
                }
            </div>
        </Container>
    )
}

export default JogoApostaConfirma;