import { Formik } from "formik";
import { Button, Card, Container, Form, InputGroup, Nav, Tab } from 'react-bootstrap';
import { IPessoa } from "../../Interfaces/Pessoa";
import useMask from "../../Components/useMask";
import {useMemo, useState} from "react";
import { every } from "lodash";
import usePessoaController from "../../Controllers/Pessoa";
import useCidadeEstado from "../../Components/useEstadoCidade";
import { useNavigate } from "react-router-dom";

const Autenticacao = () => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [modo, setModo] = useState<'c' | 'l'>('l');
    const [uf, setUF] = useState<string>('');

    const Pessoa = usePessoaController('Pessoa', false, false);
    const CidadeEstado = useCidadeEstado(uf);
    const formatter = useMask();
    const navigate = useNavigate();
    

    const titulo = useMemo<string>(() => {
        if (modo === "c") {
            return `Criar minha conta`
        } else if (modo === "l") {
            return `Entre em sua conta`
        } else {
            return `Você precisa estar logado`
        }
    }, [modo]);

    return (
        <Container className={"verticalCenterFull"} style={{minHeight: '95vh', minWidth: '50vw'}}>
            {/* <img src="./logo512.png" style={{height: '10rem'}}></img> */}
            <Tab.Container
                defaultActiveKey={(() => {
                    switch(modo) {
                        case 'c':
                            return 'c'
                        case 'l':
                            return 'l'
                        default:
                            return 'l'
                    }})()
                }
                onSelect={(eventKey) => setModo(eventKey as any)} 
            >
                <Card className="card m-3" style={{minHeight: '80vh'}}>
                    {/* className={styles.cardForm}> */}
                    <Card.Header>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="l">Fazer Login</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="c">Criar conta</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>

                    <Card.Body className="d-flex flex-wrap justify-content-center">
                        <h3 className="text-center mt-3 w-100" style={{minWidth: '50vw'}}>{titulo}</h3>
                        {
                                <Formik
                                    // validationSchema={!consultouEmpresa ? schemaConsultaEmpresa : schemaLogin}
                                    onSubmit={(e : IPessoa) => {
                                        const dados = Object.assign({}, e);
                                        dados.CELULAR = formatter.getPhone(dados.CELULAR);
                                        if (modo === 'c') {
                                            if (dados.SENHA !== dados.SENHA_CONFIRMA) {
                                                alert('Senhas não coincidem');
                                            } else {
                                                Pessoa.Cadastrar.mutateAsync(dados, {
                                                    onSuccess: () => {
                                                        navigate('/jogo');
                                                    }
                                                });
                                            }
                                        } else {
                                            Pessoa.Login.mutateAsync(dados, {
                                                onSuccess: () => {
                                                    navigate('/jogo');
                                                }
                                            });
                                        }                            
                                    }}
                                    initialValues={{
                                        CELULAR: '',
                                        SENHA: '',
                                        SENHA_CONFIRMA: '',
                                        UF: '',
                                        CIDADE: '',
                                        NOME: ''
                                    }}
                                    >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                        values,
                                        errors,
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Celular:</Form.Label>
                                                {/* <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} /> */}
                                                <InputGroup>
                                                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                                    <Form.Control 
                                                        name={'CELULAR'}
                                                        type={'text'}
                                                        value={values.CELULAR}
                                                        // onChange={ handleChange }
                                                        onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                                        isInvalid={every([errors.CELULAR, confirmado])}
                                                        size="lg"
                                                        placeholder="Celular..." 
                                                    />
                                                </InputGroup>
                                            </Form.Group>     

                                            {modo === 'c' && <>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>Nome Completo:</Form.Label>
                                                    {/* <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} /> */}
                                                    <InputGroup>
                                                        <Form.Control 
                                                            name={'NOME'}
                                                            type={'text'}
                                                            autoComplete="off"
                                                            value={values.NOME}
                                                            // onChange={ handleChange }
                                                            onChange={ handleChange }
                                                            isInvalid={every([errors.NOME, confirmado])}
                                                            size="lg"
                                                            placeholder="Nome completo..." 
                                                        />
                                                    </InputGroup>
                                            </Form.Group>    

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Estado (UF):</Form.Label>
                                                    {/* <BadgeValidacao validacao={errors.CD_CIDADE} confirmado={confirmado} /> */}
                                                        <Form.Select 
                                                            name={'UF'}
                                                            value={values.UF}
                                                            onChange={ e => {
                                                                setFieldValue("UF", e.target.value);
                                                                setUF(e.target.value);
                                                            }}
                                                            // isInvalid={every([errors.CD_CIDADE, confirmado])} 
                                                            size="lg"
                                                        >
                                                        <option value={undefined}>Selecione uma UF</option>
                                                        {CidadeEstado.Estados.map((index: string) => (
                                                            <option key={index} value={index}>
                                                                {index}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>   

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Cidade:</Form.Label>
                                                        <Form.Select
                                                            name={'CIDADE'}
                                                            value={values.CIDADE}
                                                            onChange={ e => setFieldValue("CIDADE", e.target.value) }
                                                            size="lg"
                                                            disabled={uf === ''}
                                                        >
                                                        <option value={undefined}>Selecione uma cidade</option>
                                                        {CidadeEstado.Cidades.map((index: string) => (
                                                            <option key={index} value={index}>
                                                                {index}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>     
                                            </>}

                                            <Form.Group className="mt-3">
                                                <Form.Label>Senha:</Form.Label>
                                                {/* <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} /> */}
                                                <Form.Control
                                                    name={'SENHA'}
                                                    type={'password'}
                                                    value={values.SENHA}
                                                    onChange={handleChange}
                                                    isInvalid={every([errors.SENHA, confirmado])}
                                                    size="lg"
                                                    placeholder="Senha..." 
                                                />
                                            </Form.Group>

                                            {modo === 'c' && <Form.Group className="mt-3">
                                                <Form.Label>Confirmar senha:</Form.Label>
                                                {/* <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} /> */}
                                                <Form.Control
                                                    name={'SENHA_CONFIRMA'}
                                                    type={'password'}
                                                    value={values.SENHA_CONFIRMA}
                                                    onChange={handleChange}
                                                    isInvalid={every([errors.SENHA_CONFIRMA, confirmado])}
                                                    size="lg"
                                                    placeholder="Repita a Senha..." 
                                                />
                                            </Form.Group>}

                                            <div className="w-100 d-flex justify-content-center">
                                                <Button 
                                                    className="mt-3"
                                                    variant="success" 
                                                    type="submit" 
                                                    size="lg"
                                                    onClick={() => {
                                                        setConfirmado(true);
                                                    }}
                                                >
                                                    {modo === 'c' ? 'Cadastrar' : 'Entrar'}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            }        
                        </Card.Body>
                    </Card>
                </Tab.Container>
            
        </Container>
    )
}

export default Autenticacao;