import { IAposta } from "./Aposta";
import { IJogo } from "./Jogo";

export interface IPule {
    CD_JOGO_PULE?       : number;
    CD_JOGO?            : number;
    CD_APOSTADOR?       : number;
    CD_CAMBISTA?        : number;    
    NOME?               : string;
    STATUS              : EPuleStatus;
    DT_CADASTRO?        : string | Date;
    APOSTA?             : IAposta[];
    JOGO?               : IJogo;
    CD_TIPO_JOGO?       : number | undefined;
    QTD_APOSTA?         : number; // Disponivel somente em listarTodosJogo
    VLR_APOSTA?         : number; // Disponivel somente em listarTodosJogo
    NOME_CAMBISTA?      : string; // Disponivel somente em listarTodosJogo
};

export enum EPuleStatus {
    Rascunho    = 'RA',
    Aberto      = 'AB',
    Finalizado  = 'FN'
}